import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty, isError } from 'lodash';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Checkbox,
  Hidden,
  Typography
} from '@mui/material';
import {
  Error,
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
  Check,
  GetAppOutlined,
  TaskOutlined,
  CloseOutlined,
  PanToolOutlined
} from '@mui/icons-material';
import useDynamicRefs from 'use-dynamic-refs';
import { makeStyles } from '@mui/styles';
import { Colors } from '../config/default';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  checkDocNoDuplication,
  FetchDefaultCoding,
  getApprovers,
  getDocumentDetails,
  matchPO,
  updateDocument
} from '../services/services';
import { canvasDetails, userRoles, documentTypes } from '../constants/appConstants';
import BottomDrawer from '../components/bottmDrawer';
import AccountsInput from '../components/accountsInput';
import {
  formatLineItems,
  arrangedLineitems,
  getPosition,
  filterDocuments,
  HtmlTooltip,
  getYearRange,
  isvendorValueMatching,
  deepEqual,
  formatDateOnly,
  InvoiceNumberKeys
} from '../services/common';
import {
  ValidateAnnotation,
  autoCap,
  validateG702,
  ValidateReceipt,
  ValidateEstimate,
  ValidateCodes,
  ValidateVendorAndAccount,
  ValidateDeliveryTicket,
  validateLineItems
} from '../services/validationService';
import BusinessAccountsList from '../components/businessAccountsList';
import ViewDocument from '../components/viewDocument';
import Layout from '../components/layout';
import MuiModal from '../components/muiModel';
import LoadNextAndPrevDoc from '../components/loadNextAndPrevDoc';
import { get_pending_data } from '../redux/actions/action';
import PaginationButtons from '../components/paginationButtons';
import { useToast } from '../toast/toastContext';
import '../image.css';
import { compareObjects } from '../services/validationService';
import MuiPopover from '../components/muiPopover';
import Konva from 'konva';
import patternBackground from '../assets/triangular-background-dark.png';
import ButtonDropdown from '../components/buttonDropdown';

const useStyles = makeStyles({
  primaryCheckbox: {
    '&.MuiCheckbox-colorPrimary': {
      color: Colors.TEXTGREY
    }
  }
});
export default function ResponsivePage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [validationErrors, setValidationErrors] = useState({});
  const [validationLineItemsErrors, setValidationLineItemsErrors] = useState({});
  const [currentId, setCurrentId] = useState(id);
  const queue = useSelector((state) => state?.pending?.pending[0]);
  const currentDocumentId = id;
  const documents = filterDocuments(queue);
  const currentIndex = documents?.findIndex((doc) => doc._id === currentDocumentId);
  const [imageDimensions, setImageDimensions] = useState({});
  const imgElement = React.useRef(null);
  const [actualValues, setActualValues] = useState([]);
  const [valueNames, setValueNames] = useState([]);
  const [content, setContent] = useState([]);
  const [lineItem, setLineItem] = useState([]);
  const [getRef, setRef] = useDynamicRefs();
  const [canvasDimensions, setCanvasDimensions] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [annotationType, setAnnotationType] = useState('');
  const [index, setIndex] = useState(0);
  const [indexLength, setIndexLength] = useState(0);
  const array = Array(indexLength).fill(false);
  const [buttonColors, setButtonColors] = useState(array);
  const [typeCheck, setTypeCheck] = useState(false);
  const [jobCheck, setJobCheck] = useState(false);
  const [costCheck, setCostCheck] = useState(false);
  const [phaseCheck, setPhaseCheck] = useState(false);
  const [vendorNumberCheck, setVendorNumberCheck] = useState(false);
  const [glCheck, setGlCheck] = useState(false);
  const [phase, setPhase] = useState('');
  const [costTypeCheck, setCostTypeCheck] = useState(false);
  const [isConstructionCompany, setIsConstructionCompany] = useState(false);
  const [serviceAndEquipmentPermission, setServiceAndEquipmentPermission] = useState(false);
  const userDetails = useSelector((state) => state?.userInfo?.userInfo);
  const deliveryTicketUpload = userDetails?.user?.deliveryTicketUpload;
  const defaultCodingPermisiion = userDetails?.company?.defaultCoding;
  const duplicateDocNo = userDetails?.company?.duplicateDocNo;
  const lineitemsMatchingPermission = userDetails?.company?.lineItemTotalCalculationPermission;
  const [isPoMatched, setIsPoMatched] = useState(true);
  const [isInvoiceNumberMatched, setIsInvoiceNumberMatched] = useState({});
  const [open, setOpen] = useState(false);
  const [isAmountMatched, setIsAmountMatched] = useState({});
  const [isDateMatched, setIsDateMatched] = useState({});
  const [po, setPo] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [documentDate, setdocumentDate] = useState('');
  const [permission, setPermission] = useState('');
  const [lineItemsData, setLineItemsData] = useState('');
  const [jobId, setJobId] = useState('');
  const [vendorNumber, setVendorNumber] = useState('');
  const [costId, setCostId] = useState('');
  const [glAccount, setGlAccount] = useState('');
  const [costType, setCostType] = useState('');
  const [glInfo, setGlInfo] = useState({});
  const [vendorsData, setVendorsData] = useState({});
  const [vendors, setVendors] = useState('');
  const [isLineItemsMatched, setIsLineItemsMatched] = useState([]);
  const [manualEmails, setManualEmails] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [comment, setComment] = useState([]);
  const [docName, setDocName] = useState('');
  const [retentionAmt, setRetentionAmt] = useState('');
  const [glPeriod, setGlPeriod] = useState('');
  const [glYear, setGlYear] = useState('');
  const [retentionCheck, setRetentionCheck] = useState(false);
  const [glPeriodCheck, setGlPeriodCheck] = useState(false);
  const [glYearCheck, setGlYearCheck] = useState(false);
  const [obj, setObj] = useState({});
  const [namesArray, setNamesArray] = useState([]);
  const [fetchingPO, setFetchingPO] = useState(false);
  const [activityObj, setActivityObj] = useState(false);
  const [lineItemInitialObj, setLineItemInitialObj] = useState({});
  const [openPrompt, setOpenPrompt] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [approvers, setApprovers] = useState([]);
  const [valueChanged, setValueChanged] = useState(false);
  const scrollContainerRef = useRef(null);
  const reviewScrollContainerRef = useRef(null);
  const zoom = 90;
  const [toggleDraw, setToggleDraw] = useState('');
  const [matchPoLoading, setMatchPoLoading] = useState(false);
  const [matchDtLoading, setMatchDtLoading] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [clickedBox, setClickedBox] = useState(null);
  const [boxPosition, setBoxPosition] = useState({ top: 0, left: 0 });
  const [clickedBoxRefrence, setClickedBoxRefrence] = useState('');
  const [clickedBoxIndex, setClickedBoxIndex] = useState(-1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [refId, setRefId] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [fields, setFields] = useState({
    serviceCode: '',
    equipmentNo: '',
    description: ''
  });
  const [checks, setChecks] = useState({
    serviceCodeCheck: false,
    equipmentNoCheck: false,
    descriptionCheck: false
  });
  const [duplicateDocumentNoErrorObj, setDuplicateDocumentNoErrorObj] = useState({});
  const [lineItemAmountSum, setLineItemAmountSum] = useState(0);
  const user = useSelector((state) => state?.userInfo?.userInfo?.user?.userName);
  const userRole = useSelector(
    (state) => state?.signIn?.signIn?.cognitoRes?.idToken?.payload?.given_name
  );
  const erpType = userDetails?.user?.erpType;
  const { ESTIMATE, DELIVERY_TICKET } = documentTypes;
  const smallScreen = useMediaQuery('(max-width:1000px)');
  let codes = ['Description', 'Quantity', 'Unit_Price', 'Amount', 'GlAccount'];
  if (annotationType === 'Invoice' || annotationType === ESTIMATE) {
    codes.push('Product_Code');
  }
  if (isConstructionCompany) {
    codes = [...codes, 'userJobId', 'userPhases', 'userCostId', 'userCostType'];
  }
  if (erpType === 'Foundation' && annotationType === 'Invoice' && serviceAndEquipmentPermission) {
    codes = [...codes, 'equipmentNo', 'serviceCode'];
  }

  let headers = isEmpty(lineItemInitialObj) ? codes : namesArray;

  let buttonArray = [];
  if (currentStatus && currentStatus !== 'on Hold') {
    buttonArray.push({
      name: 'On Hold',
      status: 'on Hold',
      icon: <PanToolOutlined sx={{ width: { xs: '13px', lg: '17px' }, color: Colors.YELLOW }} />,
      Color: Colors.YELLOW
    });
  }
  if (currentStatus && currentStatus !== 'Reject') {
    buttonArray.push({
      name: 'Reject',
      status: 'Reject',
      icon: <CloseOutlined sx={{ width: { xs: '13px', lg: '21px' }, color: Colors.RED }} />,
      Color: Colors.RED
    });
  }

  let size =
    obj &&
    Object?.keys(obj)?.map((items) => {
      return obj[items] && obj[items]?.length;
    });
  const [newArr, setNewArr] = useState(new Array(size?.[0] || 0).fill(0));
  const [totalItems, setTolalItems] = useState(newArr);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.ctrlKey || event.metaKey) &&
        (event.key === '=' || event.key === '-' || event.key === '0')
      ) {
        event.preventDefault();
        triggerZoomBlockMessage();
      }
      if (event.shiftKey && event.key === '+') {
        event.preventDefault();
        triggerZoomBlockMessage();
      }
    };
    const handleWheel = (event) => {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();
        triggerZoomBlockMessage();
      }
    };
    const triggerZoomBlockMessage = () => {
      showToast(ZOOMING_ERROR, 'error');
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('wheel', handleWheel);
    };
  }, []);
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const resultArray = compareObjects(lineItemInitialObj, obj);
  const showPrompt = checkObjectValues(resultArray);
  function checkObjectValues(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== true) {
        return true;
      }
    }
    return false;
  }

  const handleOpen = () => setOpenPrompt(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userClosedDrawer, setUserClosedDrawer] = useState(true);
  useEffect(() => {
    if (lineItem?.length > 0) {
      let newNamesArray = [...namesArray];
      const arragedData = arrangedLineitems(lineItem);
      arragedData?.map((items) => {
        items?.map((element) => {
          if (isEmpty(newNamesArray)) {
            newNamesArray.push(element?.name);
          } else {
            if (!newNamesArray?.includes(element?.name)) {
              newNamesArray.push(element?.name);
            }
          }
        });
      });

      const initialObj = newNamesArray?.reduce((accumulator, value) => {
        return { ...accumulator, [value]: [] };
      }, {});
      arragedData?.forEach((items) => {
        let newArray = [];
        items?.forEach((element) => {
          initialObj[element?.name].push(element?.value);
          newArray.push(element?.name);
        });
      });
      setObj(initialObj);
      setNamesArray(newNamesArray);
      if (Object.keys(lineItemInitialObj)?.length === 0 && !fetchingPO) {
        setLineItemInitialObj(initialObj);
      }
    }
  }, [lineItem, lineItemInitialObj]);

  useEffect(() => {
    setLineItemsData(obj);
    let newArr = new Array(obj?.Amount?.length || 0).fill(0);
    setTolalItems(newArr);
  }, [lineItemInitialObj, obj]); // obj and lineitem obj for fetch and slide right/left populating new lineitems
  useEffect(() => {
    setLineItemsData(obj);
    const sum = obj?.Amount?.reduce((total, amount) => {
      const numericValue = parseFloat(String(amount).replace(/[^\d.-]/g, ''));
      return total + (isNaN(numericValue) ? 0 : numericValue);
    }, 0);
    setLineItemAmountSum(sum);
  }, [obj]);
  const fetchCoding = async (venum) => {
    const fetchCoding = await FetchDefaultCoding(venum);
    if (fetchCoding?.status === 200) {
      setCostId(fetchCoding?.data?.payload?.data?.userCostId);
      setJobId(fetchCoding?.data?.payload?.data?.userJobId);
      setPhase(fetchCoding?.data?.payload?.data?.userPhases);
      setGlAccount(fetchCoding?.data?.payload?.data?.GlAccount);
      setCostType(fetchCoding?.data?.payload?.data?.userCostType);
    }
  };
  useEffect(() => {
    if (jobId?.trim() === '' && vendorNumber && defaultCodingPermisiion) {
      fetchCoding(vendorNumber);
    }
  }, [vendorNumber]);
  const validateAccountDetails = (value, name) => {
    let validationResponse = { status: true, message: '' };
    let type = annotationType;
    if (
      (erpType === 'Foundation' || erpType === 'QuickBooksDesktop') &&
      type === 'Po' &&
      isConstructionCompany
    ) {
      validationResponse = ValidateCodes(value, name);
    } else if (
      ((erpType === 'QuickBooksOnline' || erpType === 'QuickBooksDesktop') &&
        (type === 'Invoice' || type === 'Receipt' || type === 'G702')) ||
      ((erpType === 'Foundation' || erpType === 'QuickBooksDesktop') &&
        type === 'Po' &&
        !isConstructionCompany)
    ) {
      validationResponse = ValidateVendorAndAccount(value, name);
    }

    return validationResponse;
  };
  useEffect(() => {
    const newValidationErrors = {};
    lineItem?.forEach((item, index) => {
      headers?.forEach((header) => {
        const itemValue = obj?.[header]?.[index];
        const validation = validateLineItems(
          header,
          itemValue,
          annotationType,
          erpType,
          isConstructionCompany
        );
        if (!validation.status) {
          if (!newValidationErrors[index]) {
            newValidationErrors[index] = {};
          }
          newValidationErrors[index][header] = validation.message;
        }
      });
    });
    setValidationLineItemsErrors(newValidationErrors);
  }, [lineItem, obj, annotationType, erpType, isConstructionCompany]);

  const hasLineItemErrors =
    !isEmpty(validationLineItemsErrors) && Object.keys(validationLineItemsErrors)?.length > 0;

  useEffect(() => {
    const resultArray = compareObjects(lineItemInitialObj, obj);
    function differentLineItems(obj) {
      if (obj === false) {
        return true;
      }
      for (let key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] === false) {
          return true;
        }
      }
      return false;
    }
    const isDifferentLineItems = differentLineItems(resultArray);
    if (hasLineItemErrors && userClosedDrawer) {
      setIsDrawerOpen(true);
      setUserClosedDrawer(false);
    } else {
      if (userClosedDrawer && isDifferentLineItems) {
        setIsDrawerOpen(false);
      }
    }
  }, [hasLineItemErrors]);
  const classes = useStyles();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const callSource = userRole === 'Vendor' ? 'api' : 'app';
  const { ACCOUNTANT, OWNER, CLEVEL, MANAGER, VENDOR } = userRoles;

  const {
    APPROVE,
    DOC_TYPE,
    GL_ACCOUNT,
    JOB_ID,
    COST_ID,
    COST_TYPE,
    VERIFY,
    VENDOR_NUMBER,
    PHASE,
    RETENTION_AMOUNT,
    GL_YEAR,
    GL_PERIOD,
    FETCH_PO,
    FETCH_PO_DESCRIPTION,
    FETCH_SC_DESCRIPTION,
    PROJECT_ID,
    CLASS,
    INVALID_VENDOR_NAME,
    CONTENT_VENDOR,
    FROM_CONTRACTOR,
    CONTENT_PURCHASEORDER,
    CONTENT_TOTAL,
    ZOOMING_ERROR
  } = canvasDetails;
  var stage;
  var BoxReferenceId;
  var ButtonReferenceId;
  const slideRight = () => {
    drawLine('', '', '', '', imageDimensions);
    setToggleDraw('');
    setIndex((index + 1) % images?.length);
  };
  const isNotApPerson = userRole !== OWNER && userRole !== ACCOUNTANT;
  useEffect(() => {
    if (
      glInfo?.vendor &&
      vendorsData?.document &&
      vendors &&
      (userRole === OWNER || userRole === ACCOUNTANT)
    ) {
      function findVendorIndex(vendorName) {
        for (let i = 0; i < glInfo?.vendor?.length; i++) {
          if (glInfo?.vendor[i]?.name === vendorName) {
            return i;
          }
        }
        return -1;
      }
      const matchVendorIndex = findVendorIndex(vendors);
      if (matchVendorIndex !== -1) {
        if (!isEmpty(glInfo?.vendor)) {
          setVendorNumber(glInfo?.vendor[matchVendorIndex]?.id);
        } else {
          setVendorNumber(vendorsData?.document?.vendorNumber);
        }
      }
    }
  }, [glInfo?.vendor, vendors, vendorsData?.document]);
  const handleSetValueState = (value, input) => {
    switch (value?.name) {
      case 'purchase_order':
      case 'subcontract_number':
        setPo(input);
        break;

      case 'total':
      case 'current_payment_due':
        setTotalAmount(input);
        break;

      case 'application_no':
      case 'invoice_number':
      case 'receipt_number':
      case 'Estimate_no':
      case 'Po_number':
      case 'DeliveryTicket_no':
        setInvoiceNumber(input);
        break;

      case 'invoice_date':
      case 'receipt_date':
      case 'period_to':
        setdocumentDate(input);
        break;

      default:
        break;
    }
  };

  const checkDuplicationDumentNo = async (docNum) => {
    const result = await checkDocNoDuplication(
      id,
      userDetails?.company?._id,
      invoiceNumber || docNum
    );
    if (result?.status === 200) {
      setDuplicateDocumentNoErrorObj(result?.data?.payload?.data);
    } else {
      setDuplicateDocumentNoErrorObj({});
    }
  };

  const slideLeft = () => {
    drawLine('', '', '', '', imageDimensions);
    setToggleDraw('');
    const nextIndex = index - 1;
    if (nextIndex < 0) {
      setIndex(images?.length - 1);
    } else {
      setIndex(nextIndex);
    }
  };

  const handleImageLoad = (imageRef, i) => {
    const dimensions = {
      width: imageRef.width,
      height: imageRef.height
    };
    const canvasSize = {
      width: window.innerWidth - 25,
      height: window.innerHeight - 87
    };
    setImageDimensions(dimensions);
    setCanvasDimensions(canvasSize);
  };

  const handleTextClick = (index) => {
    array[index] = !array[index];
    const newButtonColors = [...buttonColors];
    newButtonColors[index] = !buttonColors[index];
    setButtonColors(newButtonColors);
  };
  const allChildSelected =
    buttonColors?.length === content?.length &&
    typeCheck &&
    (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET
      ? glCheck && jobCheck && costTypeCheck && erpType !== 'QuickBooksOnline'
      : erpType !== 'QuickBooksDesktop'
      ? phaseCheck
      : erpType !== 'DeltekComputerEase'
      ? costCheck
      : '') &&
    (annotationType === 'Invoice' && erpType === 'Foundation'
      ? serviceAndEquipmentPermission
        ? checks.equipmentNoCheck && checks.serviceCodeCheck && checks.descriptionCheck
        : checks.descriptionCheck
      : '') &&
    (annotationType !== DELIVERY_TICKET ? retentionCheck : '') &&
    vendorNumberCheck &&
    (erpType === 'DeltekComputerEase' ? glPeriodCheck && glYearCheck : '');
  const allChecksAreEnabled =
    typeCheck ||
    (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET
      ? glCheck || jobCheck || costTypeCheck || erpType !== 'QuickBooksOnline'
        ? (erpType !== 'QuickBooksDesktp' && phaseCheck) ||
          (erpType !== 'DeltekComputerEase' && costCheck)
        : ''
      : '') ||
    (annotationType === 'Invoice' && erpType === 'Foundation'
      ? serviceAndEquipmentPermission
        ? checks.equipmentNoCheck || checks.serviceCodeCheck || checks.descriptionCheck
        : checks.descriptionCheck
      : '') ||
    (annotationType !== DELIVERY_TICKET && retentionCheck) ||
    vendorNumberCheck ||
    (erpType === 'DeltekComputerEase' ? glPeriodCheck || glYearCheck : '');
  const handleParentCheckboxChange = () => {
    if (allChildSelected || buttonColors?.length > 0 || allChecksAreEnabled) {
      setButtonColors([]);
      setTypeCheck(false);
      if (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET) {
        setGlCheck(false);
        setCostTypeCheck(false);
        setJobCheck(false);
        setCostCheck(false);
        setPhaseCheck(false);
      }
      if (erpType === 'Foundation' && annotationType === 'Invoice') {
        if (serviceAndEquipmentPermission) {
          setChecks((prev) => ({ ...prev, serviceCodeCheck: false }));
          setChecks((prev) => ({ ...prev, equipmentNoCheck: false }));
        }
        setChecks((prev) => ({ ...prev, descriptionCheck: false }));
      }
      if (annotationType !== DELIVERY_TICKET) {
        setRetentionCheck(false);
      }
      setVendorNumberCheck(false);

      if (erpType === 'DeltekComputerEase') {
        setGlYearCheck(false);
        setGlPeriodCheck(false);
      }
    } else {
      setButtonColors(content.map((_, index) => !buttonColors[index]));
      setTypeCheck(true);
      if (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET) {
        setGlCheck(true);
        setCostTypeCheck(true);
        setJobCheck(true);
        if (erpType !== 'QuickBooksOnline') {
          erpType !== 'DeltekComputerEase' && setCostCheck(true);
          erpType !== 'QuickBooksDesktop' && setPhaseCheck(true);
        }
      }
      if (erpType === 'Foundation' && annotationType === 'Invoice') {
        if (serviceAndEquipmentPermission) {
          setChecks((prev) => ({ ...prev, serviceCodeCheck: true }));
          setChecks((prev) => ({ ...prev, equipmentNoCheck: true }));
        }
        setChecks((prev) => ({ ...prev, descriptionCheck: true }));
      }
      if (annotationType !== DELIVERY_TICKET) {
        setRetentionCheck(true);
      }
      setVendorNumberCheck(true);
      if (erpType === 'DeltekComputerEase') {
        setGlYearCheck(true);
        setGlPeriodCheck(true);
      }
    }
  };

  const something = buttonColors.filter((item) => item === true);
  const isTextNotChecked =
    array?.length !== buttonColors?.length || something?.length !== array?.length;

  const fetchDocumentData = async (id) => {
    setIsLoading(true);
    const res = await getDocumentDetails(id, callSource);
    if (res?.status === 200) {
      const data = res?.data?.payload?.data;
      setLineItem(data?.document?.lineItems);
      const lastActivityIndex = data?.activity?.activity?.length - 1;
      const lastUpdatedObj = data?.activity?.activity[lastActivityIndex]?.updatedObj;

      const isLastActivityEmpty =
        !lastUpdatedObj ||
        !lastUpdatedObj?.lineItems ||
        !Array?.isArray(lastUpdatedObj?.lineItems) ||
        lastUpdatedObj?.lineItems?.length === 0 ||
        lastUpdatedObj?.lineItems?.every((item) => Object?.keys(item)?.length === 0);
      setActivityObj(isLastActivityEmpty);
      let content = data?.document?.standardContent;
      content.sort((a, b) => {
        if (a.name === FROM_CONTRACTOR && b.name !== FROM_CONTRACTOR) {
          return -1;
        } else if (a.name !== FROM_CONTRACTOR && b.name === FROM_CONTRACTOR) {
          return 1;
        } else {
          return 0;
        }
      });

      setVendors(content[0]?.value);

      content.forEach((data) => {
        data.isEnable = 'false';
      });
      const filteredUsers = res?.data?.payload?.data?.companyUser?.filter((item) => {
        return item?.role !== 'Accountant' && item?.signUp === true;
      });
      const filteredmails = filteredUsers?.map((item) => item?.email);
      setManualEmails(filteredmails);
      setCurrentStatus(data?.document?.status);
      setVendorsData(res?.data?.payload?.data);
      setIndexLength(content?.length);
      setImages(data?.document?.convertedS3Keys);
      if (isNotApPerson || erpType !== 'DeltekComputerEase') {
        setGlAccount(data?.document?.GlAccount);
        setCostType(data?.document?.userCostType);
      }
      setAnnotationType(data?.document?.documentType);
      setIsConstructionCompany(data?.document?.constructionCompany);
      setServiceAndEquipmentPermission(data?.document?.serviceCodeAndEquipmentNo);
      setCostId(data?.document?.userCostId);
      setJobId(data?.document?.userJobId);
      setPhase(data?.document?.userPhases);
      setRetentionAmt(data?.document?.retamt);
      setGlYear(data?.document?.glyear);
      setGlPeriod(data?.document?.glperiod);
      setGlInfo(data?.glInformation);
      setFields((prev) => ({ ...prev, serviceCode: data?.document?.serviceCode }));
      setFields((prev) => ({ ...prev, equipmentNo: data?.document?.equipmentNo }));
      setFields((prev) => ({ ...prev, description: data?.document?.notes }));
      setRefId(data?.document?.documentReference || []);
      if (isNotApPerson || (erpType !== 'DeltekComputerEase' && erpType !== 'Foundation')) {
        setVendorNumber(data?.document?.vendorNumber);
      }
      const docNum = data?.document?.standardizeContent?.document_number;
      if (duplicateDocNo && !isEmpty(docNum) && docNum !== '-') {
        checkDuplicationDumentNo(docNum);
      }
      setPermission(data?.document?.documentType);
      const valuesArray = [];
      const namesArray = [];
      content?.forEach((element) => {
        const value = element.value.trim();
        if (
          (element.name === CONTENT_TOTAL || element.name === 'current_payment_due') &&
          value !== '' &&
          value !== '-'
        ) {
          const cleanedValue = value?.replace(/,/g, '');
          const formattedValue = parseFloat(cleanedValue)?.toFixed(2);
          valuesArray?.push(formattedValue);
        } else {
          valuesArray?.push(value);
        }

        namesArray.push(element.name);
      });
      content?.map((val) => handleSetValueState(val, val?.value));
      setActualValues(valuesArray);
      setValueNames(namesArray);
      setContent(content);
      setDocName(data?.document?.originalFileName);
      setIsLoading(false);
    } else if (res?.response?.status === 401) {
      localStorage.clear();
      navigate('/');
    } else if (res?.response?.status === 403) {
      navigate('/unauthorized');
    }
  };

  useEffect(() => {
    fetchDocumentData(id);
  }, [id]);

  const handleFieldChange = (e, index, fieldName) => {
    const { value } = e.target;
    setObj((prevObj) => {
      const newObj = { ...prevObj };
      newObj[fieldName][index] = value || [];
      setLineItemsData(newObj);
      return newObj;
    });
  };

  const validateData = (value, name, annotationType) => {
    let validationResponse = { status: true, message: '' };
    const permission = lineitemsMatchingPermission && obj?.Amount?.length >= 1;
    switch (annotationType) {
      case 'G702':
        validationResponse = validateG702(value, name, lineItemAmountSum, permission);
        break;
      case 'Receipt':
        validationResponse = ValidateReceipt(value, name, lineItemAmountSum, permission);
        break;
      case ESTIMATE:
      case 'Po':
        validationResponse = ValidateEstimate(
          value,
          name,
          annotationType,
          lineItemAmountSum,
          permission
        );
        break;
      case DELIVERY_TICKET:
        validationResponse = ValidateDeliveryTicket(
          value,
          name,
          totalAmount,
          lineItemAmountSum,
          permission
        );
        break;
      default:
        validationResponse = ValidateAnnotation(value, name, lineItemAmountSum, permission);
    }

    return validationResponse;
  };

  useEffect(() => {
    const newValidationErrors = {};
    actualValues?.forEach((value, idx) => {
      const validation = validateData(
        idx === 0 ? vendors : value,
        content[idx]?.name,
        annotationType
      );
      if (!validation.status) {
        newValidationErrors[content[idx]?.name] = validation.message;
      }
    });
    setValidationErrors(newValidationErrors);
  }, [actualValues, annotationType, vendors, lineItemAmountSum, lineitemsMatchingPermission]);

  const hasErrors = !isEmpty(validationErrors) && Object.keys(validationErrors)?.length > 0;
  const submitData = async (event, docStatus) => {
    event.preventDefault();
    let updatedData = [...content];
    actualValues?.forEach((element, idx) => {
      updatedData[idx].value = element.replace(/[$%]/g, '');
    });
    if (content[0]?.name === CONTENT_VENDOR || content[0]?.name === FROM_CONTRACTOR) {
      const vendorObject = updatedData?.find((data) => data.name === CONTENT_VENDOR);
      const contractorObject = updatedData?.find((data) => data.name === FROM_CONTRACTOR);
      if (vendorObject) {
        vendorObject.value = vendors;
      } else if (contractorObject) {
        contractorObject.value = vendors;
      }
    }
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      if (key !== undefined && lineItemsData[key]) {
        lineItemsData[key]?.forEach((val, index) => {
          if (val !== undefined && lineItemsCopy[index]) {
            const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
            if (check !== -1 && lineItemsCopy[index][check]) {
              lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
            }
          }
        });
      }
    });
    const newData = {
      modifier: user,
      content: updatedData,
      documentType: permission,
      userJobId: jobId === null ? '' : jobId,
      vendorNumber: vendorNumber,
      phase: phase,
      userCostId: costId === null ? '' : costId,
      GlAccount: glAccount === null ? '' : glAccount,
      userCostType: costType === null ? '' : costType,
      lineItems: lineItemsCopy,
      manualUsers: selectedEmails,
      comment: comment,
      status: docStatus,
      callSource: 'app',
      retamt: retentionAmt,
      glyear: glYear === null ? '' : glYear?.toString(),
      glperiod: glPeriod === null ? '' : glPeriod?.toString(),
      equipmentNo: fields.equipmentNo,
      serviceCode: fields.serviceCode,
      notes: fields.description
    };
    const updateResponse = await updateDocument(id, newData);
    if (updateResponse?.data?.metadata?.status === 'SUCCESS') {
      showToast(updateResponse?.data?.metadata?.message, 'success');
      setIsLoading(true);
      const prevId = currentId;
      const updatedDocuments = documents?.filter((doc) => doc._id !== prevId);
      dispatch(get_pending_data(updatedDocuments));
      if (documents && documents?.length > 0 && currentIndex !== documents?.length - 1) {
        const nextIndex = (currentIndex + 1) % documents.length;
        const nextDocumentId = documents[nextIndex]._id;
        setIsDrawerOpen(false);
        setImageDimensions({});
        setImages([]);
        setButtonColors([]);
        setTypeCheck(false);
        setGlCheck(false);
        setCostTypeCheck(false);
        setJobCheck(false);
        setCostCheck(false);
        setPhaseCheck(false);
        setRetentionCheck(false);
        setGlYearCheck(false);
        setGlPeriodCheck(false);
        setVendorNumberCheck(false);
        setGlAccount('');
        setCostType('');
        setVendorNumber('');
        setGlPeriod('');
        setGlYear('');
        setRetentionAmt('');
        setLineItem([]);
        setChecks({
          serviceCodeCheck: false,
          equipmentNoCheck: false,
          descriptionCheck: false
        });
        setLineItemInitialObj({});
        setClickedBox(null);
        setClickedBoxRefrence('');
        setCurrentId(nextDocumentId);
        navigate(`/canvas/${nextDocumentId}`);
        fetchDocumentData(nextDocumentId);
        setAnchorEl(false);
        drawLine('', '', '', '', imageDimensions);
      } else if (userRole === CLEVEL || userRole === MANAGER) {
        navigate('/approver');
      } else if (userRole === ACCOUNTANT) {
        navigate('/accountant');
      } else if (userRole === VENDOR) {
        navigate('/vendor');
      } else {
        navigate('/home');
      }
    } else {
      showToast(
        updateResponse?.response?.data?.metadata?.message ||
          updateResponse?.data?.metadata?.message,
        'error'
      );
    }
  };
  const checkPoMatching = async (callSrc) => {
    setFetchingPO(true);
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      if (key !== undefined && lineItemsData[key] !== undefined) {
        lineItemsData[key]?.forEach((val, index) => {
          if (val !== undefined && lineItemsCopy[index] !== undefined) {
            const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
            if (check !== -1) {
              lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
            }
          }
        });
      }
    });
    const params = {
      referenceDocumentId: callSrc === 'DeliveryTicket' ? refId[0]?.id : '',
      matchWith: callSrc,
      poNumber: callSrc === 'DeliveryTicket' ? invoiceNumber : po,
      total: totalAmount,
      lineItems: formatLineItems(lineItemsCopy),
      callPurpose: 'Match',
      date: documentDate
    };
    const result = await matchPO(id, params);
    if (result?.status == 200) {
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }
    if (callSrc === 'DeliveryTicket') {
      setIsInvoiceNumberMatched(result?.data?.payload?.data?.poNumber);
    } else {
      setIsPoMatched(result?.data?.payload?.data?.poNumber);
    }

    setIsAmountMatched(result?.data?.payload?.data?.total);
    setIsDateMatched(result?.data?.payload?.data?.date);
    setIsLineItemsMatched(result?.data?.payload?.data?.lineItem);
    setFetchingPO(false);
  };
  const handlePoMatch = (callSrc) => {
    setMatchPoLoading(true);
    checkPoMatching(callSrc);
    setMatchPoLoading(false);
  };
  const handleDtMatch = (callSrc) => {
    setMatchDtLoading(true);
    checkPoMatching(callSrc);
    setMatchDtLoading(false);
  };
  const handleClick = (buttonRef, referenceId, idx, dataArray, showLine) => {
    if (toggleDraw !== referenceId) {
      setToggleDraw(referenceId);
      let buttonPosition = getPosition('button_' + buttonRef, getRef);
      let boxPosition = getPosition('box_' + referenceId, getRef);
      BoxReferenceId = referenceId;
      ButtonReferenceId = referenceId;
      if (buttonPosition && boxPosition?.width !== 0 && boxPosition?.height !== 0) {
        drawLine(buttonPosition, boxPosition, idx, dataArray, imageDimensions, showLine);
      } else {
        drawLine('', '', idx, dataArray, imageDimensions, '');
        setClickedBox(null);
        setClickedBoxRefrence('');
      }
    } else {
      drawLine('', '', idx, dataArray, imageDimensions, '');
      setToggleDraw('');
      setClickedBox(null);
      setClickedBoxRefrence('');
    }
  };

  const handleBoxClick = (name, value, ref, topPosition) => {
    const position = getPosition(ref, getRef);
    if (!deepEqual(clickedBoxRefrence, position)) {
      setClickedBoxRefrence(position);
      if (position && position?.top && position?.left) {
        setClickedBox({ name, value });
        setBoxPosition({
          top: topPosition + position.height + 5,
          left: position.left - 550
        });
      }
    } else {
      setClickedBox(null);
      setClickedBoxRefrence('');
    }
  };

  const drawLine = (buttonPosition, boxPosition, idx, dataArray, imageDimensions, showLine) => {
    let leftSideBarSpace = 450;

    stage = new Konva.Stage({
      container: 'myCanvas',
      height: canvasDimensions?.height,
      width: canvasDimensions?.width
    });

    let layer = new Konva.Layer();
    let curve = new Konva.Path({
      data: `
      M ${leftSideBarSpace},${buttonPosition?.top + buttonPosition?.height / 2}
      Q ${(leftSideBarSpace + boxPosition?.left) / 2},${
        buttonPosition?.top + buttonPosition?.height / 2
      }
      ${boxPosition?.left},${boxPosition?.top}
  `,
      stroke: Colors.RED,
      strokeWidth: 3,
      draggable: false
    });

    let rect = new Konva.Rect({
      x: boxPosition?.left,
      y: boxPosition?.top,
      width: dataArray[idx]?.width * imageDimensions?.width || 1,
      height: dataArray[idx]?.height * imageDimensions?.height,
      stroke: Colors.RED,
      strokeWidth: 3,
      draggable: false
    });
    showLine && layer?.add(curve);
    layer?.add(rect);
    stage?.add(layer);
    if (
      !dataArray ||
      dataArray[idx]?.value === '-' ||
      dataArray[idx]?.value === '' ||
      !buttonPosition ||
      !boxPosition
    ) {
      stage = null;
      BoxReferenceId = null;
      ButtonReferenceId = null;
      return;
    }

    function updatePosition() {
      let buttonPos = getPosition('button_' + ButtonReferenceId, getRef);
      let rectPos = getPosition('box_' + BoxReferenceId, getRef);
      let newButtonHeight = buttonPos?.height;
      let newButtonTop = buttonPos?.top;
      let newBoxLeft = rectPos?.left;
      let newBoxTop = rectPos?.top;

      curve.setData(`
            M ${leftSideBarSpace},${newButtonTop + newButtonHeight / 2}
            Q ${(leftSideBarSpace + newBoxLeft) / 2},${newButtonTop + newButtonHeight / 2}
            ${newBoxLeft},${newBoxTop}
        `);
      rect?.setAttr('x', newBoxLeft);
      rect?.setAttr('y', newBoxTop);
      showLine && layer?.add(curve);
      layer?.add(rect);
    }
    const rectPos = getPosition('box_' + BoxReferenceId, getRef);
    const scrollAmount = rectPos?.top - 20;
    scrollContainerRef?.current?.scrollBy({
      top: scrollAmount,
      behavior: 'smooth'
    });

    scrollContainerRef?.current?.addEventListener('scroll', updatePosition);
    reviewScrollContainerRef?.current?.addEventListener('scroll', updatePosition);
  };

  const handleValueChange = (value, index) => {
    let updatedArray = [...actualValues];
    updatedArray[index] = value;
    setActualValues(updatedArray);
  };
  const LineItemsDataArray = [
    { name: 'userCostType', value: glInfo?.costType },
    { name: 'userPhases', value: glInfo?.phase },
    { name: 'userJobId', value: glInfo?.jobIds },
    { name: 'userCostId', value: glInfo?.costIds },
    { name: 'GlAccount', value: glInfo?.glAccount },
    { name: 'serviceCode', value: glInfo?.serviceCode },
    { name: 'equipmentNo', value: glInfo?.equipment }
  ];

  const getAllApprover = async () => {
    const documentId = id;
    const res = await getApprovers(documentId);
    if (res?.status === 200) {
      setApprovers(res?.data?.payload?.data);
    }
  };

  useEffect(() => {
    getAllApprover();
  }, []);
  const FetchPo = async (matchWith) => {
    setFetchingPO(true);
    let lineItemsCopy = [...lineItem];
    Object.keys(lineItemsData)?.forEach((key) => {
      if (key !== undefined && lineItemsData[key] !== undefined) {
        lineItemsData[key]?.forEach((val, index) => {
          if (val !== undefined && lineItemsCopy[index] !== undefined) {
            const check = lineItemsCopy[index]?.findIndex((item) => item?.name === key);
            if (check !== -1 && lineItemsCopy[index][check]) {
              lineItemsCopy[index][check].value = val === 'N/A' ? '' : val;
            }
          }
        });
      }
    });

    const params = {
      matchWith: matchWith,
      referenceDocumentId: '',
      poNumber: po,
      total: totalAmount,
      lineItems: formatLineItems(lineItemsCopy),
      callPurpose: 'Fetch'
    };

    const result = await matchPO(id, params);
    if (result?.status === 200) {
      setLineItem([]);
      showToast(result?.data?.metadata?.message, 'success');
    } else {
      showToast(
        result?.data?.metadata?.message || result?.response?.data?.metadata?.message,
        'error'
      );
    }

    const Podata = result?.data?.payload?.data.fetchedData;
    if (Podata) {
      const fillMissingValues = (field) => {
        if (!Podata[field] && Podata?.lineItems?.length > 0) {
          const firstLineItem = Podata?.lineItems[0];
          const item = firstLineItem?.find((item) => item?.name === field);
          if (item) {
            Podata[field] = item?.value;
          }
        }
      };

      fillMissingValues('userCostId');
      fillMissingValues('userJobId');
      fillMissingValues('userPhases');
      fillMissingValues('GlAccount');
      fillMissingValues('userCostType');

      setLineItem(Podata.lineItems);
      setCostId(Podata.userCostId);
      setJobId(Podata.userJobId);
      setPhase(Podata.userPhases);
      setGlAccount(Podata.GlAccount);
      setCostType(Podata.userCostType);
    }

    setOpenPrompt(false);
    setFetchingPO(false);
  };
  const POPermission =
    userDetails?.user?.poMatchPermission &&
    annotationType !== ESTIMATE &&
    annotationType !== DELIVERY_TICKET &&
    annotationType !== 'Po';
  const diablePOandSC = po === '' || po === '-' || totalAmount === '' || totalAmount === '-';
  return (
    <Grid
      container
      sx={{
        height: '100vh',
        overflow: 'hidden !important'
      }}>
      <Layout
        isDrawerOpen={isDrawerOpen}
        buttonArray={buttonArray}
        path="canvas"
        show={userDetails?.role === OWNER ? 'showUser' : ''}
        submitData={submitData}
        id={id}
        comment={comment}
        setComment={setComment}
        docName={docName}
        padding={true}
        approvers={approvers}
        annotationType={annotationType}
        setImages={setImages}
        deliveryTicketUpload={deliveryTicketUpload}
        setRefId={setRefId}
        refId={refId}
        vendor={vendors}
        po={po}
        invoiceNumber={invoiceNumber}
        date={documentDate}
        images={images}
      />
      <Container maxWidth={null} style={{ padding: 0, overflow: 'hidden !important' }}>
        <>
          <div
            id="myCanvas"
            style={{
              position: 'absolute',
              zIndex: 99,
              pointerEvents: 'none',
              overflow: 'hidden !important'
            }}></div>

          <div className="main-container">
            <div className="left-area">
              {isLoading ? (
                <div className="loader" style={{ display: 'flex', marginTop: '10rem' }}>
                  <CircularProgress size={75} sx={{ color: Colors.DARKBLUE }} />
                </div>
              ) : (
                <Grid
                  className="review-bar"
                  sx={{
                    width: '95%',
                    '&::-webkit-scrollbar': {
                      width: '0.2em'
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      outline: '1px solid slategrey'
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#fff',
                      outline: '1px solid slategrey'
                    }
                  }}
                  ref={reviewScrollContainerRef}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                    <p className="details-heading">Document Details</p>
                    {images?.length > 1 && (
                      <Grid
                        container
                        sx={{
                          height: 'max-content'
                        }}>
                        <PaginationButtons
                          disableFirstButton={index === 0}
                          disableSecondButton={index === images.length - 1}
                          slideLeft={slideLeft}
                          slideRight={slideRight}
                          index={index}
                          images={images}
                        />
                      </Grid>
                    )}
                  </div>
                  <AccountsInput
                    label={VENDOR_NUMBER}
                    state={vendorNumberCheck}
                    setState={setVendorNumberCheck}
                    data={glInfo?.vendor?.map((vendor) => vendor?.id)}
                    setPermission={setVendorNumber}
                    permission={vendorNumber}
                    allowInput={false}
                    setValueChanged={setValueChanged}
                    validateAccountDetails={validateAccountDetails}
                    name="vendorNumber"
                    setValidationErrors={setValidationErrors}
                  />
                  {content?.map((value, idx) => {
                    const isError = validateData(
                      idx === 0 ? vendors : actualValues[idx],
                      value?.name,
                      annotationType
                    );
                    return idx === 0 ? (
                      <>
                        <Hidden mdUp>
                          <Button
                            variant="contained"
                            disabled={isEmpty(images)}
                            style={{
                              position: 'absolute',
                              top: '8em',
                              right: '20px',
                              color: Colors.WHITE,
                              fontSize: '10px',
                              borderRadius: '10px',
                              background: Colors.DARKBLUE,
                              textTransform: 'none',
                              '&:hover': {
                                background: Colors.DARKBLUE
                              }
                            }}
                            onClick={() => {
                              setOpen(true);
                            }}>
                            Show Document
                          </Button>
                          <ViewDocument selectedImage={images} setOpen={setOpen} open={open} />
                        </Hidden>
                        <div
                          className="value-button"
                          ref={setRef('button_' + value.name)}
                          key={idx}
                          onClick={() => {
                            if (!smallScreen) {
                              handleBoxClick(
                                value?.name,
                                value?.value,
                                'box_' + value.name,
                                value.top * imageDimensions.height + value.height
                              );
                              handleClick(value.name, value.name, idx, content, true);
                            }
                          }}>
                          <Box className="box-style">
                            <p
                              style={{
                                marginLeft: '15px',
                                color: Colors.WHITE,
                                display: 'inline-flex'
                              }}>
                              <Check
                                sx={{
                                  fontSize: '1.5em',
                                  marginRight: '.5rem',
                                  color: buttonColors[idx] === true ? Colors.DARKBLUE : ''
                                }}
                                onClick={() => handleTextClick(idx, 'success')}
                              />
                              {autoCap(value.name)}
                            </p>
                          </Box>
                          {(value.name === CONTENT_VENDOR || value.name === FROM_CONTRACTOR) &&
                          !isEmpty(glInfo?.vendor) ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div
                                className={
                                  (!isvendorValueMatching(glInfo?.vendor, vendors) &&
                                    userDetails?.activeIntegration !== '') ||
                                  !isError?.status
                                    ? 'dropdown-with-error'
                                    : 'first-input'
                                }>
                                <BusinessAccountsList
                                  permission={vendors}
                                  setPermission={setVendors}
                                  question=""
                                  data={glInfo?.vendor?.map((vendor) => vendor?.name)}
                                  color={Colors.WHITE}
                                  allowInput={false}
                                  setValueChanged={setValueChanged}
                                />
                              </div>
                              {((!isvendorValueMatching(glInfo?.vendor, vendors) &&
                                userDetails?.activeIntegration !== '') ||
                                !isError?.status) && (
                                <HtmlTooltip
                                  title={
                                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                                      {!isError?.status ? isError?.message : INVALID_VENDOR_NAME}
                                    </Typography>
                                  }>
                                  <Error
                                    sx={{
                                      color: Colors.RED
                                    }}
                                  />
                                </HtmlTooltip>
                              )}
                            </div>
                          ) : (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <input
                                className={
                                  (!isvendorValueMatching(glInfo?.vendor, vendors) &&
                                    userDetails?.activeIntegration !== '') ||
                                  !isError?.status
                                    ? 'input-field-style-error'
                                    : 'input-field-style'
                                }
                                value={actualValues[idx]}
                                onChange={(e) => {
                                  handleValueChange(e.target.value, idx);
                                  handleSetValueState(value, e.target.value);
                                }}
                                onBlur={() => {
                                  if (
                                    InvoiceNumberKeys.includes(value?.name) &&
                                    duplicateDocNo &&
                                    !isEmpty(invoiceNumber) &&
                                    invoiceNumber !== '-'
                                  ) {
                                    checkDuplicationDumentNo();
                                  }
                                }}
                              />
                              {((!isvendorValueMatching(glInfo?.vendor, vendors) &&
                                userDetails?.activeIntegration !== '') ||
                                !isError?.status) && (
                                <HtmlTooltip
                                  title={
                                    <Typography color="inherit" sx={{ fontSize: '1em' }}>
                                      {!isError?.status ? isError?.message : INVALID_VENDOR_NAME}
                                    </Typography>
                                  }>
                                  <Error
                                    sx={{
                                      color: Colors.RED
                                    }}
                                  />
                                </HtmlTooltip>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <div
                        className="value-button"
                        key={idx}
                        onClick={() => {
                          if (!smallScreen) {
                            handleBoxClick(
                              value?.name,
                              value?.value,
                              'box_' + value.name,
                              value.top * imageDimensions.height + value.height
                            );
                            handleClick(value.name, value.name, idx, content, true);
                          }
                        }}>
                        <Box ref={setRef('button_' + value.name)} className="box-style">
                          <p
                            style={{
                              marginLeft: '15px',
                              color: Colors.WHITE,
                              display: 'inline-flex'
                            }}>
                            <Check
                              sx={{
                                fontSize: '1.5em',
                                marginRight: '.5rem',
                                color: buttonColors[idx] === true ? Colors.DARKBLUE : ''
                              }}
                              onClick={() => handleTextClick(idx, 'success')}
                            />
                            {value.name === 'Estimate_date'
                              ? value.name.replace('Estimate_date', 'Quote Date')
                              : value.name === 'Estimate_no'
                              ? value.name.replace('Estimate_no', 'Quote No')
                              : value.name === 'DeliveryTicket_date'
                              ? value.name.replace('DeliveryTicket_date', 'Document Date')
                              : value.name === 'DeliveryTicket_no'
                              ? value.name.replace('DeliveryTicket_no', 'Document No')
                              : value.name === 'purchase_order'
                              ? value.name.replace('purchase_order', 'Purchase Order/ SC')
                              : autoCap(value.name)}
                          </p>
                        </Box>
                        <div className="input-wrapper">
                          <input
                            className={
                              (value.name === CONTENT_TOTAL ||
                                value.name === 'current_payment_due') &&
                              userDetails?.user?.poMatchPermission &&
                              isAmountMatched?.status === false &&
                              !isError?.status
                                ? 'input-field-style-dual-error'
                                : !isError?.status ||
                                  (InvoiceNumberKeys.includes(value?.name) &&
                                    duplicateDocumentNoErrorObj?.match)
                                ? 'input-field-style-error'
                                : 'input-field-style'
                            }
                            value={actualValues[idx]}
                            onChange={(e) => {
                              handleValueChange(e.target.value, idx);
                              handleSetValueState(value, e.target.value);
                            }}
                            onBlur={() => {
                              if (
                                InvoiceNumberKeys.includes(value?.name) &&
                                duplicateDocNo &&
                                !isEmpty(invoiceNumber) &&
                                invoiceNumber !== '-'
                              ) {
                                checkDuplicationDumentNo();
                              }
                            }}
                          />
                          {(value.name === CONTENT_TOTAL || value.name === 'current_payment_due') &&
                            userDetails?.user?.poMatchPermission &&
                            isAmountMatched?.status === false && (
                              <HtmlTooltip
                                title={
                                  <Typography color="inherit" sx={{ fontSize: '1em' }}>
                                    {`Total is "${isAmountMatched?.value}" in the supporting document.`}
                                  </Typography>
                                }>
                                <Error
                                  sx={{
                                    color: Colors.YELLOW
                                  }}
                                />
                              </HtmlTooltip>
                            )}
                          {!isError?.status ||
                          (value.name === CONTENT_PURCHASEORDER &&
                            isPoMatched === false &&
                            userDetails?.user?.poMatchPermission &&
                            isError?.status) ||
                          ((value.name === 'invoice_date' || value.name === 'receipt_date') &&
                            isPoMatched &&
                            isDateMatched?.status === false) ||
                          ((value.name === 'invoice_number' || value.name === 'from_contractor') &&
                            isInvoiceNumberMatched?.status === false &&
                            userDetails?.user?.deliveryTicketUpload &&
                            isError?.status) ||
                          (InvoiceNumberKeys.includes(value?.name) &&
                            duplicateDocumentNoErrorObj?.match) ? (
                            <HtmlTooltip
                              title={
                                <Typography color="inherit" sx={{ fontSize: '1em' }}>
                                  {!isError?.status
                                    ? isError?.message
                                    : value.name === CONTENT_PURCHASEORDER
                                    ? 'PO does not match'
                                    : value.name === 'invoice_number' &&
                                      isInvoiceNumberMatched?.status === false &&
                                      userDetails?.user?.deliveryTicketUpload &&
                                      isError?.status
                                    ? `Invoice number is "${isInvoiceNumberMatched?.value}" in the supporting document.`
                                    : value.name === 'from_contractor' &&
                                      isInvoiceNumberMatched?.status === false &&
                                      userDetails?.user?.deliveryTicketUpload &&
                                      isError?.status
                                    ? `From contractor is "${isInvoiceNumberMatched?.value}" in the supporting document.`
                                    : value.name === 'invoice_date' || value.name === 'receipt_date'
                                    ? `Date is "${formatDateOnly(
                                        isDateMatched?.value
                                      )}" in the supporting document.`
                                    : duplicateDocumentNoErrorObj?.message}
                                </Typography>
                              }>
                              <Error
                                sx={{
                                  color:
                                    !isError?.status ||
                                    (InvoiceNumberKeys.includes(value?.name) &&
                                      duplicateDocumentNoErrorObj?.match)
                                      ? Colors.RED
                                      : Colors.YELLOW
                                }}
                              />
                            </HtmlTooltip>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    );
                  })}
                  {annotationType !== DELIVERY_TICKET && (
                    <AccountsInput
                      label={RETENTION_AMOUNT}
                      state={retentionCheck}
                      setState={setRetentionCheck}
                      setPermission={setRetentionAmt}
                      permission={retentionAmt}
                      allowInput={true}
                      setValueChanged={setValueChanged}
                      validateAccountDetails={validateAccountDetails}
                      name="retentionAmount"
                      setValidationErrors={setValidationErrors}
                    />
                  )}
                  {erpType === 'Foundation' && annotationType === 'Invoice' && (
                    <AccountsInput
                      label="Description"
                      state={checks.descriptionCheck}
                      setState={setChecks}
                      setPermission={setFields}
                      permission={fields.description}
                      allowInput={false}
                      setValueChanged={setValueChanged}
                      stateName="description"
                      checkStateName="descriptionCheck"
                      validateAccountDetails={validateAccountDetails}
                      name="description"
                      setValidationErrors={setValidationErrors}
                    />
                  )}
                  <div className="value-button">
                    <Box className="box-style">
                      <p className="paragraph-style">
                        <Check
                          sx={{
                            fontSize: '1.5em',
                            marginRight: '.5rem',
                            color: typeCheck === true ? Colors.DARKBLUE : ''
                          }}
                          onClick={() => setTypeCheck(!typeCheck)}
                        />
                        {DOC_TYPE}
                      </p>
                    </Box>
                    <div style={{ marginLeft: '.5rem' }} className="input-field" disabled>
                      {annotationType === ESTIMATE
                        ? annotationType?.replace(ESTIMATE, 'Quote')
                        : annotationType === DELIVERY_TICKET
                        ? annotationType?.replace(DELIVERY_TICKET, 'Delivery Ticket')
                        : annotationType === 'Po'
                        ? 'PO'
                        : annotationType}
                    </div>
                  </div>
                  {annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET && (
                    <>
                      <p className="details-heading">Account Details</p>
                      <AccountsInput
                        label={GL_ACCOUNT}
                        state={glCheck}
                        setState={setGlCheck}
                        data={glInfo?.glAccount}
                        setPermission={setGlAccount}
                        permission={glAccount}
                        allowInput={false}
                        setValueChanged={setValueChanged}
                        validateAccountDetails={validateAccountDetails}
                        name="GlAccount"
                        setValidationErrors={setValidationErrors}
                      />
                      {isConstructionCompany && (
                        <>
                          <AccountsInput
                            label={erpType === 'QuickBooksOnline' ? PROJECT_ID : JOB_ID}
                            state={jobCheck}
                            setState={setJobCheck}
                            data={glInfo?.jobIds}
                            setPermission={setJobId}
                            permission={jobId}
                            allowInput={false}
                            setValueChanged={setValueChanged}
                            validateAccountDetails={validateAccountDetails}
                            name="JobId"
                            setValidationErrors={setValidationErrors}
                          />
                          {erpType !== 'QuickBooksOnline' && (
                            <>
                              {erpType !== 'QuickBooksDesktop' && (
                                <AccountsInput
                                  label={PHASE}
                                  state={phaseCheck}
                                  setState={setPhaseCheck}
                                  data={glInfo?.phase}
                                  setPermission={setPhase}
                                  permission={phase}
                                  allowInput={false}
                                  setValueChanged={setValueChanged}
                                  validateAccountDetails={validateAccountDetails}
                                  name="phase"
                                  setValidationErrors={setValidationErrors}
                                />
                              )}
                              {erpType !== 'DeltekComputerEase' && (
                                <AccountsInput
                                  label={COST_ID}
                                  state={costCheck}
                                  setState={setCostCheck}
                                  data={glInfo?.costIds}
                                  setPermission={setCostId}
                                  permission={costId}
                                  allowInput={false}
                                  setValueChanged={setValueChanged}
                                  validateAccountDetails={validateAccountDetails}
                                  name="CostId"
                                  setValidationErrors={setValidationErrors}
                                />
                              )}
                            </>
                          )}

                          <AccountsInput
                            label={erpType === 'QuickBooksOnline' ? CLASS : COST_TYPE}
                            state={costTypeCheck}
                            setState={setCostTypeCheck}
                            data={glInfo?.costType}
                            setPermission={setCostType}
                            permission={costType}
                            allowInput={false}
                            setValueChanged={setValueChanged}
                            validateAccountDetails={validateAccountDetails}
                            name="CostType"
                            setValidationErrors={setValidationErrors}
                          />
                        </>
                      )}
                      {erpType === 'Foundation' &&
                        annotationType === 'Invoice' &&
                        serviceAndEquipmentPermission && (
                          <>
                            <AccountsInput
                              label="Equipment No"
                              state={checks.equipmentNoCheck}
                              setState={setChecks}
                              setPermission={setFields}
                              permission={fields.equipmentNo}
                              data={glInfo?.equipment}
                              allowInput={false}
                              setValueChanged={setValueChanged}
                              stateName="equipmentNo"
                              checkStateName="equipmentNoCheck"
                              validateAccountDetails={validateAccountDetails}
                              name="equipmentNo"
                              setValidationErrors={setValidationErrors}
                            />
                            <AccountsInput
                              label="Service code"
                              state={checks.serviceCodeCheck}
                              setState={setChecks}
                              setPermission={setFields}
                              data={glInfo?.serviceCode}
                              permission={fields.serviceCode}
                              allowInput={false}
                              setValueChanged={setValueChanged}
                              stateName="serviceCode"
                              checkStateName="serviceCodeCheck"
                              validateAccountDetails={validateAccountDetails}
                              name="serviceCode"
                              setValidationErrors={setValidationErrors}
                            />
                          </>
                        )}
                      {erpType === 'DeltekComputerEase' && (
                        <>
                          <AccountsInput
                            label={GL_PERIOD}
                            state={glPeriodCheck}
                            setState={setGlPeriodCheck}
                            setPermission={setGlPeriod}
                            permission={glPeriod}
                            data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                            allowInput={false}
                            setValueChanged={setValueChanged}
                            validateAccountDetails={validateAccountDetails}
                            name="glPeriod"
                            setValidationErrors={setValidationErrors}
                          />
                          <AccountsInput
                            label={GL_YEAR}
                            state={glYearCheck}
                            setState={setGlYearCheck}
                            setPermission={setGlYear}
                            permission={glYear}
                            data={getYearRange()}
                            allowInput={false}
                            setValueChanged={setValueChanged}
                            validateAccountDetails={validateAccountDetails}
                            name="glYear"
                            setValidationErrors={setValidationErrors}
                          />
                        </>
                      )}
                    </>
                  )}

                  <div className="buttons-bar">
                    <Grid
                      container
                      item
                      xs={12}
                      sx={{ justifyContent: 'space-between', mt: '1rem' }}>
                      <div
                        style={{
                          display: 'flex',
                          color: Colors.WHITE,
                          alignItems: 'center',
                          fontSize: '17px'
                        }}>
                        <Checkbox
                          sx={{
                            color: '#1AACAC !important',
                            paddingLeft: '0px !important',
                            marginLeft: '.9rem'
                          }}
                          classes={{
                            colorPrimary: classes.primaryCheckbox
                          }}
                          indeterminate={
                            (buttonColors?.length > 0 && buttonColors?.length < content?.length) ||
                            allChecksAreEnabled ||
                            false
                          }
                          indeterminateIcon={
                            allChildSelected ? (
                              <CheckBox />
                            ) : isEmpty(buttonColors) &&
                              (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET
                                ? glCheck === false &&
                                  jobCheck === false &&
                                  (erpType === 'Foundation' && annotationType === 'Invoice'
                                    ? serviceAndEquipmentPermission
                                      ? checks.serviceCodeCheck === false &&
                                        checks.equipmentNoCheck === false &&
                                        checks.descriptionCheck === false
                                      : checks.descriptionCheck === false
                                    : '') &&
                                  erpType !== 'QuickBooksOnline'
                                  ? erpType !== 'DeltekComputerEase'
                                    ? costCheck === false
                                    : erpType !== 'QuickBooksDesktop'
                                    ? phaseCheck === false
                                    : ''
                                  : '' && costTypeCheck === false
                                : '') &&
                              vendorNumberCheck === false &&
                              retentionCheck === false &&
                              (erpType === 'DeltekComputerEase'
                                ? glPeriodCheck === false && glYearCheck === false
                                : '') &&
                              typeCheck === false ? (
                              <CheckBoxOutlineBlank />
                            ) : !isEmpty(buttonColors) && !buttonColors.includes(true) ? (
                              <CheckBoxOutlineBlank />
                            ) : (
                              <IndeterminateCheckBox />
                            )
                          }
                          checked={buttonColors?.length === content?.length}
                          onChange={handleParentCheckboxChange}
                        />
                        {VERIFY}
                      </div>
                      <BottomDrawer
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                        isPoMatched={isPoMatched}
                        isInvoiceNumberMatched={isInvoiceNumberMatched}
                        isLineItemsMatched={isLineItemsMatched}
                        itemsData={arrangedLineitems(lineItem)}
                        handleClick={handleClick}
                        setRef={setRef}
                        setLineItemsData={setLineItemsData}
                        endIcon={<TaskOutlined sx={{ width: { xs: '13px', lg: '17px' } }} />}
                        annotationType={annotationType}
                        disabled={
                          po === '' || po === '-' || totalAmount === '' || totalAmount === '-'
                        }
                        disableDt={
                          invoiceNumber === '' ||
                          invoiceNumber === '-' ||
                          totalAmount === '' ||
                          totalAmount === '-'
                        }
                        onClick={handlePoMatch}
                        onDtClick={handleDtMatch}
                        data={LineItemsDataArray}
                        costType={costType}
                        jobId={jobId}
                        costId={costId}
                        phase={phase}
                        glAccount={glAccount}
                        obj={obj}
                        setObj={setObj}
                        namesArray={namesArray}
                        isLastActivityEmpty={activityObj}
                        fetchingPO={fetchingPO}
                        lineItemInitialObj={lineItemInitialObj}
                        openPrompt={openPrompt}
                        setOpenPrompt={setOpenPrompt}
                        showPrompt={showPrompt}
                        constructionCompany={isConstructionCompany}
                        valueChanged={valueChanged}
                        fetchPo={FetchPo}
                        lineItem={lineItem}
                        id={id}
                        callSource={callSource}
                        setLineItem={setLineItem}
                        matchPoLoading={matchPoLoading}
                        setTolalItems={setTolalItems}
                        totalItems={totalItems}
                        headers={headers}
                        handleFieldChange={handleFieldChange}
                        handleBoxClick={handleBoxClick}
                        imageDimensions={imageDimensions}
                        setClickedBoxIndex={setClickedBoxIndex}
                        matchDtLoading={matchDtLoading}
                        refId={refId}
                        serviceCode={fields.serviceCode}
                        equipmentNo={fields.equipmentNo}
                        serviceAndEquipmentPermission={serviceAndEquipmentPermission}
                        setIsLineItemsMatched={setIsLineItemsMatched}
                      />
                    </Grid>
                    <Grid container item xs={12}>
                      {annotationType !== ESTIMATE &&
                        annotationType !== DELIVERY_TICKET &&
                        annotationType !== 'Po' && (
                          <Grid
                            container
                            sx={{
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              marginBottom: '1rem'
                            }}>
                            <ButtonDropdown
                              text="Fetch"
                              width="7rem"
                              disable={!userDetails?.user?.poMatchPermission}
                              showPrompt={true}
                              icon={<GetAppOutlined sx={{ width: { xs: '13px', lg: '25px' } }} />}
                              color={Colors.WHITE}
                              openPrompt={openPrompt}
                              setOpenPrompt={setOpenPrompt}
                              options={[
                                {
                                  name: 'Purchase Order',
                                  disable: diablePOandSC,
                                  action: handleOpen,
                                  promptTitle: FETCH_PO,
                                  promptDescription: FETCH_PO_DESCRIPTION,
                                  promptLoading: fetchingPO,
                                  promptFunction: () => FetchPo('Po'),
                                  showButton: POPermission && annotationType !== 'G702'
                                },
                                {
                                  name: 'Subcontract',
                                  disable: diablePOandSC,
                                  action: handleOpen,
                                  promptTitle: 'Fetch Subcontract',
                                  promptDescription: FETCH_SC_DESCRIPTION,
                                  promptLoading: fetchingPO,
                                  promptFunction: () => FetchPo('subContract'),
                                  showButton: POPermission
                                }
                              ]}
                            />
                            <ButtonDropdown
                              text="Match"
                              width="7rem"
                              disable={
                                !userDetails?.user?.poMatchPermission &&
                                !userDetails?.user?.deliveryTicketUpload
                              }
                              icon={<TaskOutlined sx={{ width: { xs: '13px', lg: '19px' } }} />}
                              color={Colors.WHITE}
                              showPrompt={false}
                              options={[
                                {
                                  name: 'Purchase Order',
                                  disable: diablePOandSC,
                                  action: () => handlePoMatch('Po'),
                                  showButton: POPermission && annotationType !== 'G702'
                                },
                                {
                                  name: 'Delivery Ticket',
                                  disable:
                                    invoiceNumber === '' ||
                                    invoiceNumber === '-' ||
                                    totalAmount === '' ||
                                    totalAmount === '-' ||
                                    isEmpty(refId),
                                  action: () => handleDtMatch('DeliveryTicket'),
                                  showButton:
                                    (annotationType === 'Invoice' || annotationType === 'G702') &&
                                    userDetails?.user?.deliveryTicketUpload &&
                                    (isEmpty(refId) || refId?.length === 1)
                                },
                                {
                                  name: 'Subcontract',
                                  disable: diablePOandSC,
                                  action: () => handlePoMatch('subContract'),
                                  showButton: POPermission
                                }
                              ]}
                            />
                          </Grid>
                        )}

                      <Button
                        fullWidth
                        variant="contained"
                        disabled={
                          duplicateDocumentNoErrorObj?.match ||
                          (!isvendorValueMatching(glInfo?.vendor, vendors) &&
                            userDetails?.activeIntegration !== '') ||
                          hasErrors ||
                          hasLineItemErrors ||
                          isTextNotChecked ||
                          typeCheck === false ||
                          (annotationType !== ESTIMATE && annotationType !== DELIVERY_TICKET
                            ? costTypeCheck === false ||
                              glCheck === false ||
                              jobCheck === false ||
                              (erpType === 'Foundation' && annotationType === 'Invoice'
                                ? serviceAndEquipmentPermission
                                  ? checks.serviceCodeCheck === false ||
                                    checks.equipmentNoCheck === false ||
                                    checks.descriptionCheck === false ||
                                    phaseCheck === false
                                  : checks.descriptionCheck === false || phaseCheck === false
                                : '') ||
                              (erpType !== 'DeltekComputerEase' &&
                                erpType !== 'QuickBooksOnline' &&
                                costCheck === false) ||
                              (erpType !== 'QuickBooksOnline' &&
                                erpType !== 'QuickBooksDesktop' &&
                                phaseCheck === false)
                            : '') ||
                          vendorNumberCheck === false ||
                          (annotationType !== DELIVERY_TICKET && retentionCheck === false) ||
                          (erpType === 'DeltekComputerEase'
                            ? glPeriodCheck === false || glYearCheck === false
                            : '')
                        }
                        sx={{
                          height: '2.4rem',
                          marginBottom: '7px',
                          borderRadius: '10px',
                          fontWeight: 'bold',
                          position: 'relative',
                          padding: '0',
                          '&:disabled': {
                            color: 'gray',
                            cursor: 'not-allowed'
                          }
                        }}>
                        <div
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                          style={{
                            width: '90%',
                            padding: '.2rem'
                          }}
                          onClick={(e) => submitData(e, 'Approved')}>
                          {APPROVE}
                        </div>
                        {(userRole === ACCOUNTANT || userRole === OWNER) &&
                          annotationType !== ESTIMATE &&
                          annotationType !== DELIVERY_TICKET && (
                            <div style={{ width: '10%', padding: '.2rem' }}>
                              <MuiModal
                                text="Approve"
                                show="manualUsers"
                                setMails={setSelectedEmails}
                                mails={selectedEmails}
                                apiData={manualEmails}
                                marginBottom="1rem"
                                variant="contained"
                                btnHeight="2.5rem"
                                submitData={submitData}
                              />
                            </div>
                          )}
                      </Button>
                      <MuiPopover
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        startMessage="Approved"
                        approvers={approvers?.approved}
                        startPosition="top"
                        endPosition="bottom"
                      />
                    </Grid>
                  </div>
                </Grid>
              )}
            </div>
            <Hidden mdDown>
              <Grid
                sx={{
                  backgroundImage: `url(${patternBackground})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  height: 'calc( 100vh - 4.2rem )',
                  width: 'calc( 100% - 450px )',
                  display: 'flex',
                  flex: '1 1',
                  flexDirection: 'column',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: Colors.Canvas_BG,
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '0.3em',
                    height: '0em',
                    borderRadius: '20px',
                    zIndex: '999'
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.11)',
                    outline: '1px solid slategrey'
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#fff',
                    outline: '1px solid slategrey'
                  }
                }}
                onClick={() => {
                  drawLine('', '', '', '', imageDimensions);
                  setToggleDraw('');
                  if (clickedBox !== null) {
                    setClickedBoxRefrence('');
                    setClickedBox(null);
                  }
                }}
                ref={scrollContainerRef}>
                {isLoading ? (
                  <div style={{ display: 'flex' }}>
                    <CircularProgress size={75} sx={{ color: Colors.DARKBLUE }} />
                  </div>
                ) : (
                  <>
                    <LoadNextAndPrevDoc
                      id={id}
                      setCurrentId={setCurrentId}
                      setImageDimensions={setImageDimensions}
                      setButtonColors={setButtonColors}
                      setTypeCheck={setTypeCheck}
                      setGlCheck={setGlCheck}
                      setCostTypeCheck={setCostTypeCheck}
                      setJobCheck={setJobCheck}
                      setCostCheck={setCostCheck}
                      setPhaseCheck={setPhaseCheck}
                      setRetentionCheck={setRetentionCheck}
                      setGlPeriodCheck={setGlPeriodCheck}
                      setGlYearCheck={setGlYearCheck}
                      setVendorNumberCheck={setVendorNumberCheck}
                      setImages={setImages}
                      setGlAccount={setGlAccount}
                      setCostType={setCostType}
                      setVendorNumber={setVendorNumber}
                      setRetentionAmt={setRetentionAmt}
                      setGlPeriod={setGlPeriod}
                      setGlYear={setGlYear}
                      drawline={drawLine}
                      imageDimensions={imageDimensions}
                      setToggleDraw={setToggleDraw}
                      setLineItem={setLineItem}
                      setLineItemInitialObj={setLineItemInitialObj}
                      setIsDrawerOpen={setIsDrawerOpen}
                      setClickedBox={setClickedBox}
                      setClickedBoxRefrence={setClickedBoxRefrence}
                      setChecks={setChecks}
                      setUserClosedDrawer={setUserClosedDrawer}
                    />

                    <Grid
                      sx={{
                        maxHeight: '85.3vh',
                        width: `${zoom}%`,
                        position: 'relative'
                      }}>
                      {images?.length > 0 && (
                        <>
                          <img
                            id="i"
                            src={images[index]}
                            alt={index}
                            ref={imgElement}
                            onClick={() => {
                              if (clickedBox !== null) {
                                setClickedBoxRefrence('');
                                setClickedBox(null);
                              }
                            }}
                            onLoad={() => handleImageLoad(imgElement.current)}
                            style={{
                              objectFit: 'contain',
                              width:
                                annotationType === 'Po'
                                  ? '60%'
                                  : annotationType === 'Receipt'
                                  ? '80%'
                                  : 'inherit',
                              marginBottom: '5em'
                            }}
                          />
                        </>
                      )}

                      {content?.map((value, idx) =>
                        value?.page === index || value?.page === null ? (
                          <Box
                            onClick={() =>
                              handleBoxClick(
                                value?.name,
                                value?.value,
                                'box_' + value.name,
                                value.top * imageDimensions.height + value.height
                              )
                            }
                            ref={setRef('box_' + value.name)}
                            key={idx}
                            style={{
                              cursor: 'pointer',
                              height: value.height * imageDimensions.height,
                              background: '#00FF3C',
                              width: value.width * imageDimensions.width,
                              position: 'absolute',
                              top: value.top * imageDimensions.height,
                              left: value.left * imageDimensions.width,
                              opacity: 0.4,
                              zIndex: '1000'
                            }}></Box>
                        ) : (
                          ''
                        )
                      )}

                      {clickedBox && (
                        <div
                          style={{
                            position: 'absolute',
                            top: boxPosition.top,
                            left: boxPosition.left,
                            backgroundColor: 'white',
                            padding: '10px 20px',
                            zIndex: 1000,
                            backgroundColor: Colors.Canvas_BG,
                            borderRadius: '15px',
                            width: '11rem'
                          }}
                          onClick={(e) => e.stopPropagation()}>
                          <p
                            style={{
                              fontSize: '.9rem',
                              color: Colors.TOURQUISE,
                              borderBottom: '1px solid #2E97A7'
                            }}>
                            <strong>
                              {clickedBox.name === 'Estimate_date'
                                ? clickedBox.name.replace('Estimate_date', 'Quote Date')
                                : clickedBox.name === 'Estimate_no'
                                ? clickedBox.name.replace('Estimate_no', 'Quote No')
                                : autoCap(clickedBox.name)}
                            </strong>
                          </p>

                          {content?.map(
                            (value, idx) =>
                              value.name === clickedBox.name && (
                                <>
                                  <p key={idx} style={{ margin: '1.5rem 0rem' }}>
                                    {idx === 0 ? (
                                      (value.name === CONTENT_VENDOR ||
                                        value.name === FROM_CONTRACTOR) &&
                                      !isEmpty(glInfo?.vendor) ? (
                                        <div
                                          style={{
                                            width: '100%'
                                          }}>
                                          <BusinessAccountsList
                                            permission={vendors}
                                            setPermission={setVendors}
                                            question=""
                                            data={glInfo?.vendor?.map((vendor) => vendor?.name)}
                                            color={Colors.WHITE}
                                            allowInput={false}
                                            setValueChanged={setValueChanged}
                                            font="1.3rem"
                                            setDropdownOpen={setDropdownOpen}
                                            boundingBox={true}
                                          />
                                        </div>
                                      ) : (
                                        <input
                                          style={{
                                            border: 'none',
                                            fontSize: '1.3rem',
                                            outline: 'none',
                                            width: '10rem',
                                            backgroundColor: 'transparent',
                                            color: Colors.WHITE
                                          }}
                                          value={actualValues[idx]}
                                          onChange={(e) => {
                                            handleValueChange(e.target.value, idx);
                                            setVendors(e.target.value);
                                          }}
                                        />
                                      )
                                    ) : (
                                      <input
                                        style={{
                                          border: 'none',
                                          fontSize: '1.3rem',
                                          outline: 'none',
                                          width: '10rem',
                                          backgroundColor: 'transparent',
                                          color: Colors.WHITE
                                        }}
                                        value={actualValues[idx]}
                                        onChange={(e) => {
                                          handleValueChange(e.target.value, idx);
                                          handleSetValueState(value, e.target.value);
                                        }}
                                        onBlur={() => {
                                          if (
                                            InvoiceNumberKeys.includes(value?.name) &&
                                            duplicateDocNo &&
                                            !isEmpty(invoiceNumber) &&
                                            invoiceNumber !== '-'
                                          ) {
                                            checkDuplicationDumentNo();
                                          }
                                        }}
                                      />
                                    )}
                                  </p>
                                  <div style={{ float: 'right' }}>
                                    <Check
                                      sx={{
                                        fontSize: '1.8rem',
                                        marginRight: '.5rem',
                                        cursor: 'pointer',
                                        color:
                                          buttonColors[idx] === true
                                            ? Colors.DARKBLUE
                                            : Colors.WHITE
                                      }}
                                      onClick={() => {
                                        handleTextClick(idx, 'success');
                                        drawLine('', '', '', '', imageDimensions);
                                        setToggleDraw('');
                                        setClickedBoxRefrence('');
                                        setClickedBox(null);
                                      }}
                                    />
                                  </div>
                                </>
                              )
                          )}
                          {arrangedLineitems(lineItem)?.map((element, index) => {
                            return element?.map((elem, idxs) => {
                              const boxIndex = `${index}${idxs}`;
                              return headers?.map((items, idx) => {
                                if (items === clickedBox.name && boxIndex === clickedBoxIndex) {
                                  return (
                                    <>
                                      <input
                                        key={`${items}-${index}`}
                                        style={{
                                          border: 'none',
                                          fontSize: '1.3rem',
                                          outline: 'none',
                                          width: '10rem',
                                          backgroundColor: 'transparent',
                                          color: Colors.WHITE
                                        }}
                                        value={obj[items][index]}
                                        onChange={(e) => handleFieldChange(e, index, items)}
                                      />
                                      <div style={{ float: 'right' }}>
                                        <Check
                                          style={{
                                            cursor: 'pointer',
                                            fontSize: '1.8rem',
                                            color: Colors.DARKBLUE
                                          }}
                                          onClick={() => {
                                            drawLine('', '', '', '', imageDimensions);
                                            setToggleDraw('');
                                            setClickedBoxRefrence('');
                                            setClickedBox(null);
                                          }}
                                        />
                                      </div>
                                    </>
                                  );
                                }
                                return null;
                              });
                            });
                          })}
                        </div>
                      )}
                      {arrangedLineitems(lineItem)?.map((element, parentIndex) => {
                        return element?.map((items, idx) => {
                          if (
                            items?.page === index ||
                            items?.page === null ||
                            items?.page === '-' ||
                            items?.page === ''
                          ) {
                            const boxIndex = `${parentIndex}${idx}`;
                            return (
                              <Box
                                onClick={() => {
                                  if (!smallScreen) {
                                    setClickedBoxIndex(boxIndex);
                                    handleBoxClick(
                                      items?.name,
                                      items?.value,
                                      'box_' + items?.value + '**%**' + parentIndex + idx,
                                      items.top * imageDimensions.height + items.height
                                    );
                                  }
                                }}
                                ref={setRef('box_' + items?.value + '**%**' + parentIndex + idx)}
                                key={idx}
                                style={{
                                  height: items.height * imageDimensions.height,
                                  background: '#00FF3C',
                                  width: items.width * imageDimensions.width,
                                  position: 'absolute',
                                  top: items.top * imageDimensions.height,
                                  left: items.left * imageDimensions.width,
                                  opacity: 0.4,
                                  cursor: 'pointer'
                                }}></Box>
                            );
                          }
                          return '';
                        });
                      })}
                    </Grid>
                  </>
                )}
              </Grid>
            </Hidden>
          </div>
        </>
      </Container>
    </Grid>
  );
}
